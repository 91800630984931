import React from "react";

import styles from "./OffsetAnchor.module.scss";

const OffsetAnchor = ({anchorId, anchorRef}) => (
    <div className={styles.mainBox}>
        <div id={anchorId} ref={anchorRef} className={styles.anchor} />
    </div>
);

export default OffsetAnchor;