import React, { useMemo } from "react"

import Layout from "../../components/Layout"
import TitleBlock from "../../components/TitleBlock"
import OffsetAnchor from "../../components/OffsetAnchor"
import HorLine from "../../components/HorLine"

import TitleImg from "../../assets/images/pages/services/TitleImg.svg"

import styles from "./Services.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import { remark } from "remark"
import remarkHtml from "remark-html"
import createHtml from "../../helpers/createHtml"
import findLangIndex from "../../helpers/findLangIndex";
import useGetBrowserLanguage from "../../hooks/useGetBrowserLanguage"

const getTextFromTags = (str, tag) => {
  let result
  if (tag === "h4") {
    result = str.match(/<h4>(.*?)<\/h4>/g)?.map(val => {
      return val.replace(/<\/?h4>/g, "")
    })
  }
  return result?.[0]
}

const removeTitle = (str, tag) => {
  let result
  if (tag === "h4") {
    result = str.match(/<h4>(.*?)<\/h4>/g)?.map(val => {
      return str.replace(val, "")
    })
  }
  return result?.[0]
}

const ServiceItem = ({ name, icon }) => (
  <div className={styles.consultingBlock__listBlock__item}>
    <div>
      {icon}
    </div>
    <div>{name}</div>
  </div>
)

const Services = () => {
  const language = useGetBrowserLanguage()

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query ServicesPageData {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(services)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              mini_title
              title
              title_description
              section_paragraphs
              services_title
              services_list {
                image
                name
              }
              training_title
              training_description
              training_blocks_title
              training_blocks {
                image
                name
              }
            }
          }
        }
      }
    }
  `);

  const remarkObj = useMemo(
    () => remark().use(remarkHtml),
  []);

  const pageData = useMemo(() => {
    const langIdxEn = findLangIndex(allMarkdownRemark?.edges, "services/services.md", "en");

    return {
      en: {
        miniTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.mini_title,
        title: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.title,
        titleDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.title_description
          )
          .toString(),
        sectionDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.section_paragraphs
          )
          .toString(),
        servicesTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.services_title,
        services: allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.services_list,
        trainingTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.training_title,
        trainingDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter.training_description
          )
          .toString(),
        trainingBlocksTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.training_blocks_title,
        trainingBlocks:
          allMarkdownRemark?.edges?.[langIdxEn]?.node.frontmatter?.training_blocks?.map(
            item => {
              return {
                image: item?.image,
                text: remarkObj.processSync(item?.name).toString(),
              }
            }
          ),
      },
    }
  }, [remarkObj, allMarkdownRemark])

  const currLangData = pageData.en

  const renderTraidingBlocks = item => {
    return (
      <div className={styles.trainingBlock__listBlock__item}>
        <div>
          <OffsetAnchor anchorId={getTextFromTags(item?.text, "h4").toLowerCase()} />
          <div className={styles.trainingBlock__listBlock__item__title}>
            <img src={item?.image} alt="" />
            {getTextFromTags(item?.text, "h4")}
          </div>
        </div>
        <div>
          <div
            className={styles["trading-block__wrapper"]}
            dangerouslySetInnerHTML={createHtml(removeTitle(item?.text, "h4"))}
          />
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <div className={styles.mainBoxTop}>
        <TitleBlock
          miniTitle={pageData?.en?.miniTitle}
          title={pageData?.en?.title}
          image={<TitleImg className={styles.mainImg} />}
          markdowndData={pageData?.en?.titleDescription}
        />
      </div>
      <div className={styles.mainBoxBottom}>
        <div className={styles.consultingBlock}>
          <div className={styles.consultingBlock__title}>
            {pageData?.en?.servicesTitle}
          </div>

          <div className={styles.consultingBlock__listBlock}>
            {currLangData.services && currLangData.services
              .map((item, index) => (
                <ServiceItem
                  key={`Service_${index}`}
                  name={item.name}
                  icon={<img src={item.image} alt="" />}
                />
              ))}
          </div>
        </div>
        <div className={styles.trainingBlock}>
          <div className={styles.trainingBlock__title}>
            {pageData?.en?.trainingTitle}
          </div>
          <div
            className={styles.trainingBlock__info}
            dangerouslySetInnerHTML={
              createHtml(pageData?.en?.trainingDescription)
            }
          />
        </div>
        <div className={styles.trainingBlock__miniTitle}>
          {pageData?.en?.trainingBlocksTitle}
        </div>
        <div className={styles.trainingBlock__listBlock}>
          {pageData?.en?.trainingBlocks?.map(item => renderTraidingBlocks(item))}
        </div>
      </div>
    </Layout>
  )
}

export default Services
